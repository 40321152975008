<template>
  <v-tooltip v-if="tooltipText" top :disabled="tooltipDisabled">
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        :color="color"
        :disabled="disabled"
        :small="isSmall"
        :large="isLarge"
        :x-large="isXLarge"
        v-bind="attrs"
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
  <v-btn v-else icon :color="color" :disabled="disabled" :small="isSmall" :large="isLarge" :x-large="isXLarge" @click="$emit('click')">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    tooltipText: {
      type: String,
      default: '등록',
    },
    color: {
      type: String,
      default: () => '',
    },
    size: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => 'mdi-check-circle-outline',
    },
    tooltipDisabled: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    isSmall() {
      return this.size === 'small'
    },
    isLarge() {
      return this.size === 'large'
    },
    isXLarge() {
      return this.size === 'x-large'
    },
  },
}
</script>
