<template>
  <div>
    <ul class="el upcycle-gallery-item-wrap">
      <li v-for="(item, index) in galleries" :key="index" class="galleries">
        <NuxtLink :to="currentPage > 1 ? `/upcycleGallery/${item.id}?page=${currentPage}` : `/upcycleGallery/${item.id}`">
          <div class="item-image">
            <img :src="item.image" :alt="item.title" />
          </div>
          <div class="item-info">
            <v-avatar v-if="item.user.avatar !== '' && item.user.avatar" size="70" class="mb-1">
              <img :alt="item.user.nickname" :src="item.user.avatar" />
            </v-avatar>
            <v-avatar v-else color="primary" size="70" class="mb-1">
              <v-icon large class="white--text">mdi-account</v-icon>
            </v-avatar>
            <div class="like">
              <icon-button
                tooltip-text=""
                color="pink"
                :icon="item.liked ? 'mdi-heart' : 'mdi-heart-outline'"
                :tooltip-disabled="false"
                @click="cancelEdit(item, index)"
              />
              <span>{{ item.likes_count }}</span>
            </div>
            <div class="subject">{{ item.title }}</div>

            <div class="excerpt">
              {{ item.excerpt }}
            </div>
          </div>
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script>
import IconButton from '../_common/IconButton.vue'

export default {
  name: 'LatestUpcycleGalleryComponent',
  components: {
    IconButton,
  },
  props: {
    galleries: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    console.log(this.galleries)
  },
}
</script>
<style lang="scss" scoped>
.upcycle-gallery-item-wrap {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  gap: 40px 20px;
  margin-top: 20px !important;

  .galleries {
    // background-color: blue;
    a {
      display: block;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid $color__border-default;
      max-height: 440px;
      height: 100%;

      .item-image {
        position: relative;
        overflow: hidden;
        font-size: 0;
        height: 295px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: all 0.3s ease-in-out;
        }
      }

      .item-info {
        position: relative;
        padding: 40px 10px 10px 10px;

        .v-avatar {
          position: absolute;
          top: 0;
          left: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          border: 4px solid #fff !important;
        }

        > div:not(.v-avatar) {
          margin: 5px 0;
        }

        .like {
          position: absolute;
          display: flex;
          align-items: center;
          right: 10px;
          top: 0;
        }
        .subject {
          font-weight: 500;
          position: relative;
        }
        .excerpt {
          @include ellipsis(2, 1.4rem);
        }
      }

      &:hover,
      &:active {
        .item-image {
          // border: 10px solid red;
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .upcycle-gallery-item-wrap {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
