var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"el material-list-item-wrap"},_vm._l((_vm.materials),function(material,index){return _c('li',{key:index},[_c('NuxtLink',{attrs:{"to":`/material/${material.id}?page=${_vm.currentPage}${_vm.salesType ? `&salesType=${_vm.salesType}` : ''}${
        _vm.categories.length > 0 ? _vm.categories.map((category) => `&categories=${category}`).join('') : ''
      }${_vm.region ? `&region=${_vm.region}` : ''}`}},[_c('div',{staticClass:"item-image"},[_c('img',{attrs:{"src":material.photos[0],"alt":material.title}})]),_vm._v(" "),_c('div',{staticClass:"item-info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(material.title)+_vm._s(material.title))]),_vm._v(" "),_c('div',{staticClass:"price"},[_vm._v(_vm._s(material.price)+"원")]),_vm._v(" "),_c('div',{staticClass:"comments"},[_c('v-icon',[_vm._v("mdi-comment-text-outline")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(material.comments_count))])],1),_vm._v(" "),_c('div',{staticClass:"etc"},[_c('span',{staticClass:"salesType",class:{
              sell: material.salesType === 'S',
              buy: material.salesType === 'B',
              free: material.salesType === 'F',
              stopselling: material.salesType === 'SS',
            }},[_vm._v("\n            "+_vm._s(material.salesType === 'S'
                ? '팝니다'
                : material.salesType === 'B'
                ? '삽니다'
                : material.salesType === 'F'
                ? '무료나눔'
                : '판매중지')+"\n          ")]),_vm._v("\n\n          "+_vm._s(material.material_category.name)+" •\n          "+_vm._s(material.region)+"\n        ")])])])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }