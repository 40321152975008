<template>
  <div class="upcyclingHowTo">
    <v-container>
      <h1>Upcycling How to</h1>
      <div class="youtube">
        <v-responsive :aspect-ratio="16 / 9">
          <iframe
            src="https://www.youtube.com/embed/VFmYrFXg3vI"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </v-responsive>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'UpcyclingHowToComponent'
}
</script>
<style lang="scss" scoped>
.upcyclingHowTo {
  padding: 50px 0;

  .youtube {
    max-width: 960px;
    margin: 30px auto 0;
    .v-responsive iframe {
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
  }
}
</style>
