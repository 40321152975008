<template>
  <ul class="el material-list-item-wrap">
    <li v-for="(material, index) in materials" :key="index">
      <NuxtLink
        :to="`/material/${material.id}?page=${currentPage}${salesType ? `&salesType=${salesType}` : ''}${
          categories.length > 0 ? categories.map((category) => `&categories=${category}`).join('') : ''
        }${region ? `&region=${region}` : ''}`"
      >
        <div class="item-image">
          <img :src="material.photos[0]" :alt="material.title" />
        </div>
        <div class="item-info">
          <div class="title">{{ material.title }}{{ material.title }}</div>
          <div class="price">{{ material.price }}원</div>
          <div class="comments">
            <v-icon>mdi-comment-text-outline</v-icon>
            <span>{{ material.comments_count }}</span>
          </div>
          <div class="etc">
            <span
              class="salesType"
              :class="{
                sell: material.salesType === 'S',
                buy: material.salesType === 'B',
                free: material.salesType === 'F',
                stopselling: material.salesType === 'SS',
              }"
            >
              {{
                material.salesType === 'S'
                  ? '팝니다'
                  : material.salesType === 'B'
                  ? '삽니다'
                  : material.salesType === 'F'
                  ? '무료나눔'
                  : '판매중지'
              }}
            </span>

            {{ material.material_category.name }} &bull;
            {{ material.region }}
          </div>
        </div>
      </NuxtLink>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MaterialListComponent',
  props: {
    materials: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    salesType: {
      type: String,
      default: 'all',
    },
    categories: {
      type: Array,
      default: () => [],
    },
    region: {
      type: String,
      default: '전체',
    },
  },
  // mounted () {
  //   console.log(this.materials)
  // }
}
</script>
<style lang="scss" scoped>
.material-list-item-wrap {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-columns: repeat(4, minmax(0, 1fr));
  width: 100%;
  gap: 40px 20px;
  margin-top: 20px;

  li {
    a {
      display: block;
      .item-image {
        border-radius: 10px;
        overflow: hidden;
        font-size: 0;
        img {
          transition: all 0.3s ease-in-out;
        }
      }

      .item-info {
        margin-top: 10px;

        > div {
          margin: 5px 0;
        }
        .title {
          font-size: 1.125rem !important;
          font-weight: 600;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 1.5;
          min-height: calc(1.5em * 2);
          // max-height: calc(1.5em * 2);
        }

        .price {
          margin-top: 10px;
          font-family: $font__gmarket;
          font-size: 0.85rem;
        }

        .etc {
          margin-top: 10px;
          font-size: 0.85rem;
          span {
            display: inline-block;
            padding: 2px 5px;
            border-radius: 3px;
            margin-right: 3px;
            text-align: center;
            font-size: 0.8rem;
            &.sell {
              color: #fff;
              background-color: #ff6b6b;
            }
            &.buy {
              color: #fff;
              background-color: #20c997;
            }

            &.free {
              color: #fff;
              background-color: #ff922b;
            }
            &.stopselling {
              color: #fff;
              background-color: #444;
            }
          }
        }
      }

      &:hover,
      &:active {
        .item-image {
          // border: 10px solid red;
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .material-list-item-wrap {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 992px) {
  .material-list-item-wrap {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 767px) {
  .material-list-item-wrap {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
