<template>
  <div class="header-hero">
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="7" class="d-flex align-center hero-image">
          <!-- <v-img src="/images/green-earth.png"></v-img> -->
          <img src="/images/green-earth.png" alt="재료공장, 환경보호를 위한 재사용/재활용 재료 거래 플랫폼" />
        </v-col>
        <v-col cols="12" sm="12" md="5" class="d-flex align-center justify-center hero-desc">
          <div>
            <h1>재료공장</h1>
            <p class="slogan">환경보호를 위한 재사용/재활용 재료 거래 플랫폼</p>
            <p class="desc">
              재료공장은 오래되거나 철거될 인테리어 및 건축 재료들과 소품들을<br class="hidden-mobile">쉽게 거래할 수 있는 Map 플랫폼입니다.<br>여러분의 작은 실천으로 환경보호에 동참해주세요.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'MainHeaderHeroComponent',
  components: {},
  data() {
    return {
      sampleData: '',
    }
  },
}
</script>
<style lang="scss" scoped>
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes upAndDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.header-hero {
  max-height: 600px;
  overflow: hidden;

  .hero-image img {
    // animation: rotating 200s linear infinite;
    animation: upAndDown 5s ease-in-out infinite;
  }
  .hero-desc {
    margin-top: -50px;
    // font-family: $font__scoredream;
    font-weight: 700;

    h1 {
      // font-family: $font__kangwon;
      // font-family: $font__scoredream;
      font-family: $font__kangwon;
      font-weight: normal;
      font-size: 3rem;
      color: #02b3a7;
      // font-weight: 900;
    }

    .slogan {
      color: #999;
      font-size: 1rem;
    }

    .dest {
      font-size: 1.25rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .header-hero {
    max-height: none;
    .hero-desc {
      text-align: center;
      margin-top: 0;
      // .hero-title {
      //   font-size: 32px;
      // }
      h1 {
        font-size: 2rem;
        text-align: center;
      }

      .slogan {
        font-size: 0.875rem;
      }

      .desc {
        font-size: 1rem;
        line-height: 1.75rem;
        .hidden-mobile {
          display: none;
        }
      }
    }
  }
}
</style>
