<template>
  <div>
    <MainHeaderHero />

    <div class="latest-materials">
      <v-container>
        <h1>최근 등록된 재료</h1>
        <LatestMaterials :materials="materials" />
        <NuxtLink to="/material" class="more-link">재료 더 보기</NuxtLink>
      </v-container>
    </div>
    <div class="latest-upcyclegallery">
      <v-container>
        <h1>업사이클 갤러리</h1>
        <LatestUpcycleGallery :galleries="galleries" :current-page="1" />
        <NuxtLink to="/upcycleGallery" class="more-link">갤러리 더 보기</NuxtLink>
      </v-container>
    </div>
    <UpcyclingHowTo />
  </div>
</template>

<script>
import LatestMaterials from '~/components/frontend/MaterialList.vue'
import LatestUpcycleGallery from '~/components/frontend/UpcycleGalleryList.vue'
// import MainSlider from '~/components/frontend/MainSlider.vue'
import MainHeaderHero from '~/components/frontend/MainHeaderHero.vue'
import UpcyclingHowTo from '~/components/frontend/UpcyclingHowTo.vue'

export default {
  name: 'IndexPage',
  components: {
    LatestUpcycleGallery,
    LatestMaterials,
    // MainSlider,
    MainHeaderHero,
    UpcyclingHowTo,
  },
  // async asyncData(context) {
  //   let latestItems = []
  //   try {
  //     const materialsResponse = await context.$axios.get('/material/getMaterials', {
  //       params: {
  //         page: 1,
  //         itemsPerPage: 8,
  //       },
  //     })

  //     // console.log(materialsResponse.data.data)

  //     const latestItemsResponse = await context.$axios.get(`/upcycleGallery/getLatestGalleries`, {
  //       params: {
  //         page: 1,
  //         itemsPerPage: 3,
  //       },
  //     })

  //     latestItems = latestItemsResponse.data.data.map((item) => {
  //       const content = JSON.parse(item.content)
  //       const firstImageBlock = content.blocks.find((block) => block.type === 'image')
  //       item.image = firstImageBlock ? firstImageBlock.data.file.url : ''
  //       return item
  //     })

  //     const materials = materialsResponse.data.data
  //     return {
  //       materials,
  //       latestItems,
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // },
  data() {
    return {
      materials: [],
      galleries: [],
    }
  },
  created() {
    this.getMaterials()
    this.getGalleries()
  },
  mounted() {
    this.disableRightClick = (event) => {
      event.preventDefault()
    }
    window.addEventListener('contextmenu', this.disableRightClick)
  },
  // unmounted() {
  //   window.removeEventListener('contextmenu', this.disableRightClick)
  // },
  methods: {
    async getMaterials() {
      const response = await this.$axios.get('/material/getMaterials', {
        params: {
          page: 1,
          itemsPerPage: 8,
        },
      })

      this.materials = response.data.data
    },

    async getGalleries() {
      const response = await this.$axios.get(`/upcycleGallery/getLatestGalleries`, {
        params: {
          page: 1,
          itemsPerPage: 3,
        },
      })

      this.galleries = response.data.data.map((item) => {
        const content = JSON.parse(item.content)
        const firstImageBlock = content.blocks.find((block) => block.type === 'image')
        item.image = firstImageBlock ? firstImageBlock.data.file.url : ''
        return item
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.latest-materials {
  width: 100%;
  padding: 50px 0;

  border-top: 1px solid $color__border-default;

  h1 {
    font-family: $font__kangwon;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .latest-materials {
    padding: 30px 0;
  }
}
.latest-upcyclegallery {
  background-color: $color__background-default;
  padding: 50px 0;
  // border-top: 1px solid $color__border-default;

  h1 {
    font-family: $font__kangwon;
    font-weight: normal;
    margin-bottom: 20px;
  }
}

.more-link {
  display: table;
  padding: 10px 20px;
  background-color: $color__sig;
  color: #fff;
  margin: 50px auto;
  border-radius: 4px;
}
</style>
